import { useGlobalContext } from '../context/appContext';
import React from 'react';
import styled from 'styled-components';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';

const JobColumns = () => {
  const { 
    sortTask, 
    sortTasks, 
  } = useGlobalContext();

  const switchSort = (val) => {
    let newSortTask = sortTask;
    if(newSortTask.includes(val)) {
      if(newSortTask.includes('-'+val)) {
        newSortTask =newSortTask.replace('-'+val, val)
      } else {
        
        newSortTask =newSortTask.replace(val,'-'+val)
      }
    } else {
      newSortTask = val
    }
    sortTasks(newSortTask)
  }


  return (
    <Wrapper>
      <span>
        <span className="sort-col" onClick={()=>switchSort('name')}>name {(sortTask.includes('name') && !sortTask.includes('.'))?sortTask.includes('-name')?(<RiArrowUpSFill />):(<RiArrowDownSFill />):''}</span>
        <span className="sort-col" onClick={()=>switchSort('appointedTo')}>, Assigned {sortTask.includes('appointedTo')?sortTask.includes('-appointedTo')?(<RiArrowUpSFill />):(<RiArrowDownSFill />):''}</span>
        <span className="sort-col" onClick={()=>switchSort('createdAt')}>, From date {sortTask.includes('createdAt')?sortTask.includes('-createdAt')?(<RiArrowUpSFill />):(<RiArrowDownSFill />):''}</span>
      </span>
        
      <span>
         <span className="sort-col" onClick={()=>switchSort('importance')}>Importance {sortTask.includes('importance')?sortTask.includes('-importance')?(<RiArrowUpSFill />):(<RiArrowDownSFill />):''}</span>
      </span>
      <span>
        <span className="sort-col" onClick={()=>switchSort('status')}>status {sortTask.includes('status')?sortTask.includes('-status')?(<RiArrowUpSFill />):(<RiArrowDownSFill />):''}</span>
      </span>
      <span>
        <span className="sort-col" onClick={()=>switchSort('tillDate')}>Up to {sortTask.includes('tillDate')?sortTask.includes('-tillDate')?(<RiArrowUpSFill />):(<RiArrowDownSFill />):''}</span>
        
      </span>
      <span className='action'>action</span>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: var(--grey-200);
  color: var(--grey-500);
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
  display: grid;
  grid-template-columns: 140px 140px 70px 90px 70px;
    align-items: start;
    padding: 10px 10px;
    column-gap: 10px;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
  font-size: var(--small-text);
  font-weight: 600;
  .action {
    margin-left: 1rem;
  }
  @media (min-width: 776px) {
    grid-template-columns: 0.8fr 0.8fr 100px 150px 100px;
    align-items: start;
    padding: 1rem 1.5rem;
    column-gap: 1rem;
  }
`;

export default JobColumns;

import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch  } from 'react-router-dom';
import { Home, Dashboard, Register, Edit, Error, PrivateRoute } from './pages';
import { useGlobalActionContext } from './context/appContext';

function App() {
  const {
    deleteAllTempFiles
  } = useGlobalActionContext();


  useEffect(() => {
    const fun = async(ev) => {
      ev.preventDefault();
      if(JSON.parse(sessionStorage.getItem('uploadFiles'))) {
        deleteAllTempFiles();
        return ev.returnValue = '';
      }
      return false;
    }

    window.addEventListener('beforeunload', fun, true);
    return () => {
      window.removeEventListener('beforeunload', fun, true);
    } 
  });

  return (
    <Router>
      <div className="taskWrpper">
        <Switch>
          <Route path='/' exact>
            <Home />
          </Route>
          <PrivateRoute path='/dashboard' exact>
            <Dashboard />
          </PrivateRoute>
          <Route path='/register'>
            <Register />
          </Route>
          <Route path='/edit/:id'>
            <Edit />
          </Route>

          <Route path='*'>
            <Error />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import styled from 'styled-components';
import logo from '../assets/taskslogo.png';
import { Link } from 'react-router-dom';
import { AiOutlineLogin } from 'react-icons/ai';


import { useGlobalContext } from '../context/appContext';

const Navbar = () => {
  const { user, logout,avatar } = useGlobalContext();
  // console.log(avatar);
  return (
    <Wrapper>
      <div className='nav-center'>
        <Link to='/' className="lacrima">
          <img className="logo" src={logo} alt='jobs app' />
        </Link>
        {user && (
          <div className='nav-links'>
            <h5>{avatar && (
              <span className="avatar-wrapper" >
                <img src={avatar.url} alt={avatar.name} />
              </span>
            )} hello, <b>{user}</b></h5>
            <button className='btn logout' onClick={logout}>
              <AiOutlineLogin />
            </button>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  .nav-center {
    width: var(--fluid-width);
  }
  img {
    display: block;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .nav-links h5 {
    margin: 0;
    margin-right: 2rem;
  }
  @media (min-width: 576px) {
    height: 6rem;

    .nav-center {
      max-width: var(--max-width);
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .nav-links {
      margin-top: 0;
    }
    img {
      margin-top: 0;
      margin-left: 0;
    }
  }
`;

export default Navbar;

import {
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  SET_USER,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_ERROR,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  LOGOUT_USER,
  SET_LOADING,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_ERROR,
  DELETE_JOB_ERROR,
  FETCH_SINGLE_JOB_SUCCESS,
  FETCH_SINGLE_JOB_ERROR,
  EDIT_JOB_ERROR,
  EDIT_JOB_SUCCESS,
  UPLOAD_FILES_SUCCES,
  UPLOAD_FILES_ERROR,
  INIT_UPLOADED_FILES,
  SET_SORT_TASKS,
  SET_FILTER
} from './actions'

const reducer = (state, action) => {
  if (action.type === SET_LOADING) {
    return { ...state, isLoading: true, showAlert: false, editComplete: false }
  }

  if (action.type === REGISTER_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      user: action.payload.name,
      userId: action.payload.userId,
      avatar: JSON.stringify(action.payload.avatar) === '{}'?null:action.payload.avatar,
      errorMessage:'',
    }
  }
  if (action.type === REGISTER_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      user: null,
      userId: null,
      showAlert: true,
      errorMessage: action.payload,
    }
  }

  if (action.type === SET_USER) {
    
    return { ...state, user: 
      action.payload.name, 
      userId: action.payload.id, 
      avatar:action.payload.avatar,
      filterType: (typeof action.payload.filterType !=='undefined')?action.payload.filterType:'',  
      filterStatus:(typeof action.payload.filterStatus !=='undefined')?action.payload.filterStatus:'',
      filterImportance:(typeof action.payload.filterImportance !=='undefined')?action.payload.filterImportance:'',
    }
  }
  if (action.type === LOGOUT_USER) {
    return {
      ...state,
      user: null,
      userId: null,
      showAlert: false,
      jobs: [],
      isEditing: false,
      editItem: null,
    }
  }

  if (action.type === FETCH_JOBS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      editItem: null,
      singleJobError: false,
      editComplete: false,
      jobs: action.payload.jobs,
      jobsLength: action.payload.length
    }
  }
  if (action.type === FETCH_JOBS_ERROR) {
    return { ...state, isLoading: false }
  }
  if (action.type === FETCH_USERS_SUCCESS) {
    return {
      ...state,
      editItem: null,
      editComplete: false,
      users: action.payload,
    }
  }
  if (action.type === FETCH_USERS_ERROR) {
    return { ...state, isLoading: false }
  }
  if (action.type === CREATE_JOB_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      jobs: [action.payload,...state.jobs],
      jobsLength: state.jobsLength+1,
    }
  }
  if (action.type === CREATE_JOB_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
    }
  }

  if (action.type === DELETE_JOB_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
    }
  }

  if (action.type === FETCH_SINGLE_JOB_SUCCESS) {
    return { ...state, isLoading: false, editItem: action.payload }
  }
  if (action.type === FETCH_SINGLE_JOB_ERROR) {
    return { ...state, isLoading: false, editItem: null, singleJobError: true }
  }

  if (action.type === EDIT_JOB_SUCCESS) {

    return {
      ...state,
      isLoading: false,
      editComplete: true,
      editItem: action.payload,
    }
  }
  if (action.type === EDIT_JOB_ERROR) {
    return {
      ...state,
      isLoading: false,
      editComplete: true,
      showAlert: true,
    }
  }
  if(action.type === UPLOAD_FILES_SUCCES) {
    return {
      ...state,
      uploadedFiles: action.payload,
    }
  }
  if(action.type === UPLOAD_FILES_ERROR) {
    return {
      ...state
    }
  }
  if(action.type === INIT_UPLOADED_FILES) {
    return {
      ...state,
      uploadedFiles: [],
    }
  }
  if(action.type === SET_SORT_TASKS) {
    return {
      ...state,
      sortTask: action.payload,
    }
  }

  if(action.type === SET_FILTER) {
    return {
      ...state,
      [action.payload.filter]: action.payload.value,
    }
  }
  
  throw new Error(`no such action : ${action}`)
}

export default reducer

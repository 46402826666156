export const SET_LOADING = 'SET_LOADING'

export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR'

export const SET_USER = 'SET_USER'
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS'
export const FETCH_JOBS_ERROR = 'FETCH_JOBS_ERROR'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR'
export const LOGOUT_USER = 'LOGOUT_USER'
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS'
export const CREATE_JOB_ERROR = 'CREATE_JOB_ERROR'
export const DELETE_JOB_ERROR = 'DELETE_JOB_ERROR'
export const FETCH_SINGLE_JOB_SUCCESS = 'FETCH_SINGLE_JOB_SUCCESS'
export const FETCH_SINGLE_JOB_ERROR = 'FETCH_SINGLE_JOB_ERROR'

export const EDIT_JOB_SUCCESS = 'EDIT_JOB_SUCCESS'
export const EDIT_JOB_ERROR = 'EDIT_JOB_ERROR'
export const UPLOAD_FILES_SUCCES = 'UPLOAD_FILES_SUCCES'
export const UPLOAD_FILES_ERROR = 'UPLOAD_FILES_ERROR'
export const INIT_UPLOADED_FILES = 'INIT_UPLOADED_FILES'
export const SET_SORT_TASKS = 'SET_SORT_TASKS'
export const SET_FILTER = 'SET_FILTER'
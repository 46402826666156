import axios from 'axios'
import '../axios'
import {io} from "socket.io-client";
import React, { useContext, useEffect, useReducer,useRef } from 'react'
import {
  SET_LOADING,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  SET_USER,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_ERROR,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_ERROR,
  DELETE_JOB_ERROR,
  FETCH_SINGLE_JOB_SUCCESS,
  FETCH_SINGLE_JOB_ERROR,
  EDIT_JOB_SUCCESS,
  EDIT_JOB_ERROR,
  UPLOAD_FILES_SUCCES,
  UPLOAD_FILES_ERROR,
  INIT_UPLOADED_FILES,
  SET_SORT_TASKS,
  SET_FILTER,
} from './actions'
import reducer from './reducer'

const initialState = {
  user: null,
  userId: null,
  avatar: null,
  isLoading: false,
  jobs: [],
  jobsLength:0,
  users:[],
  showAlert: false,
  editItem: null,
  singleJobError: false,
  editComplete: false,
  errorMessage:'',
  uploadedFiles:[],
  perPage:5,
  sortTask:'',
  importanceList: [{id:'normal',name:'Normal'},{id:'important',name:'Important'},{id:'not important',name:'Not important'}],
  statusList: [{id:'appointed',name:'Appointed'},{id:'started',name:'Started'},{id:'completed',name:'Completed'},{id:'exceeded',name:'Exceeded'}],
  typeList:[{id:'for_me',name:'Tasks for me'},{id:'mine',name:'Created by me'}],
  filterType:'',
  filterAppointed:'',
  filterStatus:'',
  filterImportance:'',
  filterName:'',
  uploadsUrl:'https://node.testerwl.ro/uploads/',
  uploadsApp:'tasks',
}
const AppContext = React.createContext()
const AppActionContext = React.createContext()

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const refSort = useRef(state.sortTask);
  const constRefFilterType = useRef(state.filterType);
  const constRefFilterAppointed = useRef(state.filterAppointed);
  const constRefFilterStatus = useRef(state.filterStatus);
  const constRefFilterImportance = useRef(state.filterImportance);
  const constRefFilterName = useRef(state.filterName);

  const setLoading = () => {
    dispatch({ type: SET_LOADING })
  }

  const socket = io(axios.defaults.baseURL.replace("api/v1", ""),{
    path: "/socket-path/",
    // transports: ['websocket', 'polling', 'flashsocket'],
  });

  
  const register = async (userInput, selectedFile) => {
    //console.log(userInput);
    setLoading()
    try {
      const res = await uploadFilesTesterwlFinal(selectedFile,'avatar',true);
      
      //not yet state.uploadedFiles but in payload
      if(res.type === UPLOAD_FILES_SUCCES) {
        let uploadedFiles = res.payload
        userInput.avatar = uploadedFiles[0];
      }
      
      const { data } = await axios.post(`/auth/register`, {
        ...userInput,
      })
      
      initUploadedFiles();
      dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user })
      localStorage.setItem(
        'user',
        JSON.stringify({ name: data.user.name, id:data.user.userId, avatar:data.user.avatar, token: data.token })
      )
    } catch (error) {
     
      let err = ''
      if(typeof error.response.data !== 'undefined') {
        if(typeof error.response.data.msg !== 'undefined') {
          err = error.response.data.msg;
        }
      }
      if(typeof error.response.data.err.errors!== 'undefined'){
        console.log(error.response.data.err.errors)
          //err = error.response.data.err.errors.name.message
        }
        if(typeof error.response.data.err.code!== 'undefined'){
          err = 'User allready exists'
        }
      console.log(error.response);
      dispatch({ type: REGISTER_USER_ERROR, payload:err })
    }
  }

  // login
  const login = async (userInput) => {
    setLoading()
    try {
      const { data } = await axios.post(`/auth/login`, {
        ...userInput,
      })
      dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user })
      localStorage.setItem(
        'user',
        JSON.stringify({ name: data.user.name,id:data.user.userId,avatar:data.user.avatar, token: data.token })
      )
    } catch (error) {
      let err = ''
      if(typeof error.response.data !== 'undefined') {
        if(typeof error.response.data.msg !== 'undefined') {
          err = error.response.data.msg;
        }
      }
      if(typeof error.response.data.err!== 'undefined') {
        if(typeof error.response.data.err.errors!== 'undefined'){
          err = error.response.data.err.errors.name.message
        }
      }
     // console.log(error.response);
      dispatch({ type: REGISTER_USER_ERROR, payload:err })
    }
  }

  // logout
  const logout = () => {
    //socket.emit("logout", {user:{name: state.user, userId:state.userId}});
    localStorage.removeItem('user')
    dispatch({ type: LOGOUT_USER })
  }

  // fetch jobs
  const fetchJobs = async (page, onpageload=false) => {
    if(onpageload) {
      setLoading()
      constRefFilterType.current = state.filterType;
      constRefFilterStatus.current =  state.filterStatus;
      constRefFilterImportance.current = state.filterImportance;
    }
    try {
      let query= '';

      if(constRefFilterType.current !=='') {
        if(constRefFilterType.current==='for_me') {
          query=query+`&appointedTo=${state.userId}`;
        }
        if(constRefFilterType.current==='mine') {
          query=query+`&createdBy=${state.userId}`;
        }
      }
      if(constRefFilterAppointed.current!=='') {
        query=query+`&appointedTo=${constRefFilterAppointed.current}`;
      }
      if(constRefFilterImportance.current!=='') {
        query=query+`&importance=${constRefFilterImportance.current}`;
      }
      if(constRefFilterStatus.current!=='') {
          query=query+`&status=${constRefFilterStatus.current}`;
      }
      if(constRefFilterName.current!=='') {
        query=query+`&name=${constRefFilterName.current}`;
      }
      if(refSort.current !=='') {
        query=query+`&sort=${refSort.current}`;
      }


      const { data } = await axios.get(`/jobs?page=${page}&per_page=${state.perPage}${query}`)
      //console.log(data);
      dispatch({ type: FETCH_JOBS_SUCCESS, payload: data })
      //console.log(data);
    } catch (error) {
      dispatch({ type: FETCH_JOBS_ERROR })
      logout()
    }
  }

  // fetch users
  const fetchUseres = async () => {
    //setLoading()
    try {
      const { data } = await axios.get(`/auth`)
      dispatch({ type: FETCH_USERS_SUCCESS, payload: data.users })
    } catch (error) {
      dispatch({ type: FETCH_USERS_ERROR })
      //logout()
    }
  }

  
  const createJob = async (userInput,selectedFiles) => {
    //setLoading()
    try {
       //console.log(userInput);
      let newInput = Object.assign({}, userInput);
      newInput.attachmentsUrls = []

      const { data } = await axios.post(`/jobs`, {
        ...newInput,
      })
      
      //filelist is read only 
      let files = selectedFiles;//userInput.attachmentsUrls;
      
      if(files.length>0) {
        const jobId = data.job._id;

        if(jobId !== 'undefined') {
           const res = await uploadFilesTesterwlFinal(files,jobId);
       
          //not yet state.uploadedFiles but in payload
          if(res.type === UPLOAD_FILES_SUCCES) {
            let newAttachments = res.payload;
            userInput.attachmentsUrls = newAttachments;

            const result = await axios.patch(`/jobs/job/${jobId}`, {
              ...userInput,
            })
            initUploadedFiles();
            dispatch({ type: CREATE_JOB_SUCCESS, payload: result.data.job })
          }
        }
      } else {
        initUploadedFiles();
        dispatch({ type: CREATE_JOB_SUCCESS, payload: data.job })
      }

      setFilter('filterType','');
      setFilter('filterAppointed','');
      setFilter('filterImportance','');
      setFilter('filterStatus','');
      setFilter('filterName','');
      sortTasks('-createdAt');
      fetchJobs(0);
    } catch (error) {
      dispatch({ type: CREATE_JOB_ERROR })
    }
  }


  const deleteJob = async (jobId) => {
    //setLoading()
    try {
      
      const result = await axios.get(`/jobs/job/${jobId}`)
      
      if(result.statusText === 'OK') {
        await axios.delete(`https://node.testerwl.ro/delete.php`, {data:{filename:'all',app:state.uploadsApp, folder:jobId}})
        await axios.delete(`/jobs/job/${jobId}`)
        await axios.delete(`/comments/deleteTask/${jobId}`)
        
        fetchJobs(0);
      }
      
    } catch (error) {
      dispatch({ type: DELETE_JOB_ERROR })
    }
  }

  const fetchSingleJob = async (jobId) => {
    setLoading()
    try {
      const { data } = await axios.get(`/jobs/job/${jobId}`)
      dispatch({ type: FETCH_SINGLE_JOB_SUCCESS, payload: data.job })
    } catch (error) {
      dispatch({ type: FETCH_SINGLE_JOB_ERROR })
    }
  }
  const editJob = async (jobId, userInput,newFiles, deletedOldFiles) => {
    setLoading()
    try {
      let newAttachments = [];
      let files = newFiles;

      //delete old existing files how were deleted in frontend
      Promise.all(deletedOldFiles.map(async(file)=>{
        await onlyDeleteFile(file,jobId);
      }));

      //upload new files
      if(files.length>0) {
        const res = await uploadFilesTesterwlFinal(files,jobId);
        if(res.type === UPLOAD_FILES_SUCCES) {
          newAttachments = res.payload;
        }
      }

      //concat uniques
      
      userInput.attachmentsUrls = [...new Map(userInput.attachmentsUrls.concat(newAttachments).map(item =>[item['name'], item])).values()]
      const result = await axios.patch(`/jobs/job/${jobId}`, {
        ...userInput,
      })
      //console.log(result);
      initUploadedFiles();
      dispatch({ type: EDIT_JOB_SUCCESS, payload: result.data.job })
    } catch (error) {
      dispatch({ type: EDIT_JOB_ERROR })
    }
  }

  const initUploadedFiles = () => {
    dispatch({ type: INIT_UPLOADED_FILES })
  }

  const uploadFilesTesterwlFinal = async(selectedFiles, folder='',unique=false ) => {

    let result = {};
    try {
      const data = new FormData();
      let path = state.uploadsUrl+state.uploadsApp+'/';

      for (let i = 0; i < selectedFiles.length; i++) {
        data.append('files[]', selectedFiles[i])
      }
      data.append('app',state.uploadsApp)
      if(folder !== '') {
        data.append('folder',folder)
        path = path+folder+'/';
      }
      if(unique ===true) {
        data.append('unique', 'yes')
      }

      const res = await axios.post(`https://node.testerwl.ro/upload.php`, data)
      console.log(res)
      if(res.status===200) {
        console.log(res)
         if(res.data.length>0){
          let newUploadedFiles = [];

          if(folder !== 'tmp') {
            initUploadedFilesAndDeleteTemp()
          } else {
            newUploadedFiles= state.uploadedFiles;
          }

          res.data.map(file => {
              newUploadedFiles = [...newUploadedFiles,{name: file.filename, url:path+file.filename, mimetype:file.mimetype,  size:file.size }]
              return true;
          });

          //only for array same 
          const uniqueNewUploadedFiles = [...new Map(newUploadedFiles.map(item =>[item['name'], item])).values()];
          
          //for deleting tmp files on refresh page
          if(folder === 'tmp') {
            sessionStorage.setItem('uploadFiles', JSON.stringify(uniqueNewUploadedFiles))
          }

          result = { type: UPLOAD_FILES_SUCCES, payload: uniqueNewUploadedFiles };
        }
      }
    } catch(error) {
      result = { type: UPLOAD_FILES_ERROR, payload: error }
    }

    dispatch(result);
    return result;
  }

  const initUploadedFilesAndDeleteTemp = async() => {
    const allPromise = Promise.all(state.uploadedFiles.map(async(file) => {
      if(file.url.includes('/tmp/')) {
        await onlyDeleteFile(file,'tmp');
      }
      //delete if not temp ????
    }));
  
    return allPromise;
  }

  const uploadFiles = async(selectedFiles, attached='non') => {
    try {
      const data = new FormData()
      for (let i = 0; i < selectedFiles.length; i++) {
        data.append('files', selectedFiles[i])
      }
      
      const res = await axios.post(`/upload/multiple`, data)
      
      if(res.statusText === 'OK') {
        
        if(res.data.length>0){
          let newUploadedFiles = [];

          if(attached !== 'create') {
            if(attached === 'non') {
              newUploadedFiles = state.uploadedFiles;
            }
            //uniques files vs objects
            res.data.map(file => {
              let ind=-1;
              state.uploadedFiles.filter((item,index)=>{
                if(item.name===file.filename) {
                  ind=index;
                  return true
                }
                return false
              });
              
              if(ind>=0) {
                newUploadedFiles[ind] = {name: file.filename, url:axios.defaults.baseURL+'/public/'+file.filename, mimetype:file.mimetype, size:file.size}
              } else {
                newUploadedFiles = [...newUploadedFiles,{name: file.filename, url:axios.defaults.baseURL+'/public/'+file.filename, mimetype:file.mimetype,  size:file.size }]
              }
              return 'ok';
            })
          }
          //console.log(newUploadedFiles);
          dispatch({ type: UPLOAD_FILES_SUCCES, payload: newUploadedFiles })
        }
      }
    } catch(error) {
      dispatch({ type: UPLOAD_FILES_ERROR, payload: error })
    }
  } 


  const onlyDeleteFile = async(file,folder='') => {
    try{
      await axios.delete(`https://node.testerwl.ro/delete.php`, {data:{filename:file.name,app:state.uploadsApp, folder:folder}}) 
    } catch(error) {
      dispatch({ type: UPLOAD_FILES_ERROR, payload: error })
    }
  }

  const deleteFileTesterwlFinal = async(file, folder='') => {
    try{
      const res = await axios.delete(`https://node.testerwl.ro/delete.php`, {data:{filename:file.name,app:state.uploadsApp, folder:folder}}) 
      
      if(res.status === 200) {
        if(res.data.message === 'OK') {
          let newUploadedFiles = state.uploadedFiles.filter(item=>item.name!==file.name)
          dispatch({ type: UPLOAD_FILES_SUCCES, payload: newUploadedFiles })
        }
      }
    } catch(error) {
      dispatch({ type: UPLOAD_FILES_ERROR, payload: error })
    }
  }

  const deleteFile = async(file) => {
    try{
      //console.log('delete');
      const res = await axios.delete(`/upload/delete/${file.name}`) 
      //console.log(res);
      if(res.status === 200) {
        if(res.data === 'OK') {
          let newUploadedFiles = state.uploadedFiles.filter(item=>item.name!==file.name)
          dispatch({ type: UPLOAD_FILES_SUCCES, payload: newUploadedFiles })
        }
      }
    } catch(error) {
      dispatch({ type: UPLOAD_FILES_ERROR, payload: error })
    }
  }

  const downloadFileTesterwl = async (event, file, folder='tmp') => {
    event.preventDefault()
    
    if(typeof file.name!=='undefined') {
      const url = 'https://node.testerwl.ro/download.php?app='+state.uploadsApp+'&folder='+folder+'&file='+file.name;

     // create a hidden anchor element
      const anchor = document.createElement("a");
      anchor.style.display = "none";

      // Set the <a> tag's href to blob url
      // and give it a download name
      anchor.href = url;
      anchor.download = file.name;

      // Append anchor and trigger the download
      document.body.appendChild(anchor);
      anchor.click();
    }
  }

  const downloadFile = async (event, file) => {

  }

  const sortTasks = (value) => {
    refSort.current =  value;
    dispatch({ type: SET_SORT_TASKS, payload: value })
  }

  const setFilter =  (filter, value) => {
    const original = JSON.parse(localStorage.getItem('user'));

    if(filter ==='filterType' || filter==='filterStatus' || filter==='filterImportance') {
      original[filter] = value;
      localStorage.setItem('user', JSON.stringify(original));
    }
    if(filter ==='filterType') {
      constRefFilterType.current = value;
    } 
    if(filter ==='filterAppointed') {
      constRefFilterAppointed.current = value;
    }
    if(filter ==='filterStatus') {
      constRefFilterStatus.current = value;
    }
    if(filter ==='filterImportance') {
      constRefFilterImportance.current = value;
    }
    if(filter ==='filterName') {
      constRefFilterName.current = value;
    }

    dispatch({ type: SET_FILTER, payload: {filter,value} })
  }

  const deleteAllTempFiles = async(unde='') => {
    let data = JSON.parse(sessionStorage.getItem('uploadFiles'));
    
    if(data) {
      
      Promise.all(data.map(async(file)=>{
        if(file.url.includes('tmp')) {
            await axios.delete(`https://node.testerwl.ro/delete.php`, {data:{filename:file.name,app:'tasks', folder:'tmp'}})
        }
        return true;
      }));
      sessionStorage.clear();
      // if(unde !=='') {

      //   alert(unde);
      // }
    }
  }

  useEffect(() => {
    const user = localStorage.getItem('user')
    if (user) {
      const newUser = JSON.parse(user)
      dispatch({ type: SET_USER, payload: newUser })
    }
  }, [])


  
  return (
    <AppContext.Provider
      value={{
        ...state,
        socket,
        setLoading,
        register,
        login,
        logout,
        createJob,
        deleteJob,
        editJob,
        downloadFile,
        deleteFile,
        sortTasks,
        setFilter
      }}
    >
      <AppActionContext.Provider 
        value={{
          fetchJobs,
          fetchUseres,
          fetchSingleJob,
          initUploadedFiles,
          initUploadedFilesAndDeleteTemp,
          deleteAllTempFiles,
          uploadFiles,
          uploadFilesTesterwlFinal,
          deleteFileTesterwlFinal,
          downloadFileTesterwl,
        }}
      >
        {children}
      </AppActionContext.Provider>
    </AppContext.Provider>
  )
}
// make sure use
export const useGlobalContext = () => {
  return useContext(AppContext)
}

export const useGlobalActionContext = () => {
  return useContext(AppActionContext)
}


export { AppProvider }

import styled from 'styled-components';
import { useState, useEffect,useRef } from 'react';
import { useGlobalContext,useGlobalActionContext } from '../context/appContext';
import Navbar from '../components/Navbar';
import Jobs from '../components/Jobs';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import { Calendar } from 'react-date-range';
import moment from 'moment';
import logo from '../assets/taskslogo.png';
import { RiArrowDownSFill, RiArrowUpSFill,RiSearchLine,RiFileDownloadFill } from 'react-icons/ri';
import { BiTask } from 'react-icons/bi';
import { AiFillDelete } from 'react-icons/ai';


function Dashboard() {
  const { 
    isLoading, 
    showAlert, 
    createJob, 
    users,  
    userId,
    uploadedFiles, 
    jobsLength,
    perPage,
    sortTask,
    importanceList,
    statusList,
    typeList,
    setFilter,
    filterType,
    filterAppointed,
    filterImportance,
    filterStatus,
    filterName,
    socket
  } = useGlobalContext();
  const {
    fetchJobs,
    fetchUseres,
    initUploadedFiles,
    uploadFilesTesterwlFinal,
    deleteFileTesterwlFinal,
    downloadFileTesterwl,
    deleteAllTempFiles
  } = useGlobalActionContext();

  const minDateTime = moment().format(moment.HTML5_FMT.DATETIME_LOCAL);
  const [values, setValues] = useState({ 
    name: '', 
    description: '',
    appointedTo: userId,
    tillDate: moment().add(30, 'minutes').format(moment.HTML5_FMT.DATETIME_LOCAL),
    importance:'normal',
    status: 'appointed',
    attachmentsUrls:[],
    public: false
  });
  const [page, setPage] = useState(0);
  const refPage = useRef(page);
  const [showNewTask, setShowNewTask] = useState(false);
  const [showTestModeAlert, setShowTestModeAlert] = useState(false);
  let ref = useRef(null);
  const inputFilesRef = useRef();
  const [selectedFiles, setSelectedFiles] =useState([]);
  const [errorUploading, setErrorUploading] = useState([]);
  const maxAllowedSize = 7*1024*1024;

  const handleClickOutside = (event) => {
    // if (ref.current && !ref.current.contains(event.target)) {
    //   setShowNewTask(false)
    //   if(userId==='618b96609685e30023e61a11'){
    //     if(inputFilesRef.current.files.length>0) {
    //       for (var i = 0; i < inputFilesRef.current.files.length; i++) {
    //         deleteFileTesterwl(inputFilesRef.current.files[i]);
    //       }
    //       inputFilesRef.current.value =[];
    //       setShowTestModeAlert(false);
    //     }
    //   }
    // }
  };
  
  const selectFilter = (e) => {
    if(e.target.name === 'filterType') {
      if(e.target.value === 'for_me') {
        setFilter('filterAppointed','');
      }
    }
    setFilter(e.target.name,e.target.value)
  }

  const handleChange = (e) => {
    let val = e.target.value
    if(e.target.name === 'public') {
      val = e.target.checked;
    }
    setValues({ ...values, [e.target.name]: val });
  };

  const onChangeHandler = (event) => {
    event.preventDefault();
    if(event.target.files.length>0) {
      
      let errors = [];
      for(let i=0; i<event.target.files.length;i++) {
        if (parseInt(event.target.files[i].size) > maxAllowedSize) {
          errors= [...errors, 'File '+event.target.files[i].name + ' larger then' +maxAllowedSize/1024+' kb']
        }
      }
      if (errors.length>0) {
        setErrorUploading([...errors, 'Please try again!']);
        event.target.value=[];
      } else {
        setErrorUploading([]);
        setValues({ ...values, attachmentsUrls: event.target.files });
        let newSelected = selectedFiles.concat(Array.from(event.target.files));
        setSelectedFiles( [...new Map(newSelected.map(item =>[item['name'], item])).values()] )
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorUploading([]);
    const { name, description, appointedTo ,tillDate } = values;
    
    if(userId==='618b96609685e30023e61a11'){
      //alert('In test mode you could not add Taskas')
      setShowTestModeAlert(true);
    } else {
      if (name && description && appointedTo && tillDate) {
        createJob(values,selectedFiles);
        //inputFilesRef.current.value =[];
        setSelectedFiles([]);
        setValues({ 
          name: '', 
          description: '',
          appointedTo: userId,
          tillDate: moment().add(30, 'minutes').format(moment.HTML5_FMT.DATETIME_LOCAL),
          importance:'normal',
          status: 'appointed',
          attachmentsUrls:[],
          public: false
        });
      }
    }
  };

  const deleteFile = (e,file) => {
    e.preventDefault(); 
    deleteFileTesterwlFinal(file,'tmp');
    setSelectedFiles(selectedFiles.filter(f=>f.name!==file.name))
    //inputFilesRef.current.value =newFileListArr;
  }
  const getTasksData = (res) => {
    //console.log(res)
    //nu in update ptr ca face editItem null
    if(!window.location.pathname.includes('/edit/')) {
      fetchJobs(refPage.current);
    }
  }
  useEffect(() => {
    //console.log(editItem);
    fetchJobs(0,true);
    socket.on("get_tasks_data", getTasksData);
    fetchUseres();
    initUploadedFiles();
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      deleteAllTempFiles();
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const updatePageState = (newState) => {
    refPage.current = newState;
    setPage(newState);
  }

  useEffect(() => {
    fetchJobs(page);
  },[page])

  useEffect(() => {
    setPage(0);
    fetchJobs(0);
  },[sortTask,
    filterType,
    filterAppointed,
    filterImportance,
    filterStatus,
    filterName])

  useEffect(() => {
    if(values.attachmentsUrls.length>0) {
      uploadFilesTesterwlFinal(values.attachmentsUrls,'tmp');
    }
  }, [values.attachmentsUrls]);

  // console.log(selectedFiles);
  //console.log(uploadedFiles)
  
  const pagenr = jobsLength===0?0:parseInt((jobsLength-1)/perPage)+1;

  const pages = [];
  for(let i = 0; i < pagenr; i++) {
    pages.push(i+1)
  } 
  
  return (
    <>
      <Navbar />

      <Wrapper className='page'>
        {showAlert && (
          <div className='alert alert-danger'>
            there was an error, please try again
          </div>
        )}
        {showTestModeAlert && (
          <div className='alert alert-danger'>
            In test mode you could not add Taskas
          </div>
        )}
        <div ref={ref}>
        <div className="top-action-section" >
          <button ref={ref} className="btn switch-form" style={{ backgroundColor:'#F7941E'}} onClick={()=>setShowNewTask(!showNewTask)}>{!showNewTask?(<span>New Task <RiArrowDownSFill /></span>):(<span>Hide new task<RiArrowUpSFill /></span>)}</button>
        </div>
        {(
          <div className={showNewTask?"form-wrapper max":"form-wrapper"}>
            <form className='job-form' onSubmit={handleSubmit} >
              <div className='form-row'>
                <input type="text" name="name" value={values.name} placeholder='Name' onChange={handleChange} className="form-input margin-bottom" />
                
                <textarea 
                  className="form-textarea"
                  name="description" 
                  value={values.description} 
                  placeholder="Description"
                  onChange={handleChange}
                >
                </textarea>
                <div>
                  <label htmlFor="files"  className="btn-dashed">{errorUploading.length>0?(
                    errorUploading.map((item, index) => (<div key={index}>{item}</div>))
                  ):'Select Attachments'}</label>
                  <input id="files" ref={inputFilesRef} name="files"  style={{visibility:'hidden'}} type="file" multiple onChange={onChangeHandler}  />
                </div>
                <div className="filesWrapper">
                  {uploadedFiles.map((file, index) => (
                    <span key={index} style={{marginRight:'10px'}}>
                      
                      <a  href={file.url} target="_blank" rel="noreferrer">
                        {file.mimetype && file.mimetype.includes('image/')?(
                          <img src={file.url} alt={file.name} width="40px" />
                        ):file.name} 
                      </a>
                      <div className="images-actions">
                        <button onClick={(e)=>downloadFileTesterwl(e,file)} style={{marginLeft:'5px', marginRight:'5px'}}>
                          <RiFileDownloadFill />
                        </button>
                        <button onClick={(e)=>deleteFile(e,file)} style={{marginLeft:'5px', marginRight:'5px'}}>
                          <AiFillDelete />
                        </button>
                      </div>
                    </span>
                  ))}
                </div>
              </div>

              <div className='form-row'>
        
                <input className="form-input" type="datetime-local" name="tillDate" value={values.tillDate} min={minDateTime} onChange={handleChange} />
                <label className="form-label" htmlFor="tillDate">Limit date to accomplish: </label>

                <select className="form-select" name="importance" value={values.importance} onChange={handleChange}>
                  {importanceList.map((item)=>(
                    <option key={item.id} value={item.id} >{item.name}</option>
                  ))}
                </select>
                <label className="form-label" htmlFor="appointedTo">Asigned to: </label>
                <select className="form-select" name="appointedTo" value={values.appointedTo?values.appointedTo:userId} onChange={handleChange}>
                  {users.map((user,index)=>(
                    <option key={index} value={user._id}>{user.name}</option>
                  ))}
                </select>
                <label className="form-label" htmlFor="status">Status: </label>
                <select className="form-select" name="status" value={values.status} onChange={handleChange}>
                  {statusList.map((item)=>(
                    <option key={item.id} value={item.id} >{item.name}</option>
                  ))}
                </select>
              </div>
              
              <div>
                <button type='submit' className='btn task' disabled={isLoading}>
                  {isLoading ? 'Adding New Task...' : 'Add Task'} <BiTask />
                </button><br />
                <div className="check-wrapper">
                  <input type="checkbox" id="public" name="public" checked={values.public}  onChange={handleChange} />
                  <label className="form-label" htmlFor="public"> Public</label>
                </div>
                <img className="logo left" src={logo} alt='jobs app' />
              </div>
              
            </form>
          </div>
        )}
        </div>
        <div className="tasks-filter">
          <span className="filters">
            <select 
              className={filterType!==''?"filter-item form-select":"filter-item form-select default-value"} 
              name="filterType" value={filterType} onChange={(e)=>selectFilter(e)}>
              <option className="default-value" value="">All tasks</option>
              {typeList.map(item=>(
                <option className="not-default-value" key={item.id} value={item.id}>{item.name}</option>
              ))}
            </select>
            {filterType!=='for_me' && (
              <select 
                className={filterAppointed!==''?"filter-item form-select":"filter-item form-select default-value"}  
                name="filterAppointed" value={filterAppointed} onChange={(e)=>selectFilter(e)}>
                <option className="default-value" value="">All Assigned</option>
                {users.map(item=>(
                  <option className="not-default-value" key={item._id} value={item._id}>{item.name}</option>
                ))}
              </select>
            )}
            
            <select 
              className={filterImportance!==''?"filter-item form-select":"filter-item form-select default-value"} 
              name="filterImportance" value={filterImportance} onChange={(e)=>selectFilter(e)}>
              <option className="default-value" value="">Importance</option>
              {importanceList.map(item=>(
                <option className="not-default-value" key={item.id} value={item.id}>{item.name}</option>
              ))}
            </select>
            <select 
              className={filterStatus!==''?"filter-item form-select":"filter-item form-select default-value"} 
              name="filterStatus" value={filterStatus} onChange={(e)=>selectFilter(e)}>
              <option className="default-value" value="">Status</option>
              {statusList.map(item=>(
                <option className="not-default-value" key={item.id} value={item.id}>{item.name}</option>
              ))}
            </select>
            <span className="search-wrapper">
              <input type="text" className="filter-item form-select " name="filterName" value={filterName} placeholder="Search task..." onChange={(e)=>selectFilter(e)} />
              <RiSearchLine /> 
            </span>
          </span>
          <span className="pagination">
            <b>Pages </b>
            {page>0 && (<span className="page-item" onClick={()=>updatePageState(page-1)}>prev</span>)}
            <span>
              {pagenr>0 && pages.map((item,index)=>(
                <span key={index} className={item===(page+1)?"page-item current":"page-item"} onClick={()=>updatePageState(item-1)} >{item}</span>
              ))}
            </span>
            {(page+1)<pagenr && (<span className="page-item" onClick={()=>updatePageState(page+1)}>next</span>)}
          </span>
        </div>
        <div className="ohidden">
          <Jobs/>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  margin-top: 1rem;
  .ohidden{
    overflow:hidden;

  }
  .scrooling {
    overflow:auto;
  }
  .job-form {
    background: var(--white);
    display: grid;
    row-gap: 1rem;
    column-gap: 0.5rem;
    align-items: start;
    margin-bottom: 1rem;
    border-radius: var(--borderRadius);
    padding: 1.5rem;
    .form-input {
      padding: 0.75rem;
    }

    .form-input:focus {
      outline: 1px solid var(--primary-500);
    }
    .form-row {
      margin-bottom: 0;
    }
    .btn {
      padding: 0.75rem;
    }
    @media (min-width: 776px) {
      grid-template-columns: 1.2fr 0.8fr auto;
      .btn {
        
        padding: 1rem 2rem;
      }
      column-gap: 2rem;
    }
  }
  .alert {
    max-width: var(--max-width);
    margin-bottom: 1rem;
  }
  .tasks-filter{
    position:relative;
    top:10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .filter-item {
    margin-right:5px;
    cursor:pointer;
  }
  .filter-item.form-select {
    width:auto;
  }
  .search-wrapper {
    position:relative;
  }
  .search-wrapper svg {
    position:absolute;
    top:2px;
    right:10px;
  }
  .page-item {
    padding:5px 10px;
    background:#fff;
    margin-left:5px;
    border: 1px solid blue;
    border-radius:2px;
    cursor:pointer;
  }
  .page-item.current {
    background:#e2e8f0;
  }
`;

export default Dashboard;

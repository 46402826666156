//import React,{useRef, useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import main from '../assets/main3.png';
import { 
  useGlobalContext,
  // useGlobalActionContext
} from '../context/appContext';
import { Redirect } from 'react-router-dom';
import logo from '../assets/taskslogo.png';
import { 
  AiOutlineLogin,
  // AiFillDelete 
} from 'react-icons/ai';
//import { FaFileUpload } from 'react-icons/fa';
//import DownloadLink from "react-download-link";


function Home() {
  const { 
    user,
    //uploadedFiles
  } = useGlobalContext();
  // const {
  //   uploadFilesTesterwl,
  //   deleteFileTesterwl,
  //   downloadFileTesterwl
  // } = useGlobalActionContext();
  // const [selectedFiles,setSelectedFiles] = useState([]);
  // const inputEl = useRef(null);

  // const onChangeHandler = (event) => {
  //   event.preventDefault();
  //   setSelectedFiles(event.target.files);
  // };

  // const deleteFile = (file) => {
  //   inputEl.current.value =[];
  //   deleteFileTesterwl(file);
  // }

  // useEffect(() => {
  //   if(selectedFiles.length>0) {
  //     //uploadFiles(selectedFiles);
  //     uploadFilesTesterwl(selectedFiles)
  //   }
  // }, [selectedFiles]);

  // useEffect(() => {
  //   if(uploadedFiles.length>0) {
  //     setSelectedFiles([]);
  //   }
  // }, [uploadedFiles]);
  
  
  return (
    <>
      {user && <Redirect to='/dashboard' />}
      <Wrapper>
        <nav>
          <img className="logo" src={logo} alt='jobs app' />
        </nav>
        <div className='container page'>
          <div className='info'>
            <h1>Tasks for me and my mats</h1>
            <p>
              Here we put the tasks in a calendar to not forget what we have to do, me and my friends.
            </p>
            {
              // <div>
              //   <label htmlFor="files" className="btn">Add Files <FaFileUpload /></label>
              //   <input id="files" ref={inputEl} name="files" style={{visibility:'hidden'}}  multiple type="file" onChange={onChangeHandler}  />
              // </div>
              // <div id="filesWrapper">
              //   {uploadedFiles.map((file, index) => (
              //     <span key={index} style={{marginRight:'10px'}}>
              //       <a href={file.url} onClick={(e)=>downloadFileTesterwl(e,file)}>
              //         {file.mimetype.includes('image/')?(
              //           <img src={file.url} alt={file.name} width="100px" />
              //         ):file.name} 
              //       </a>
              //       <button onClick={()=>deleteFile(file)} style={{marginLeft:'5px', marginRight:'15px'}}>
              //         <AiFillDelete />
              //       </button>
              //     </span>
              //   ))}
              // </div>
            }

            <Link to='/register' className='btn hero-btn'>
              Login / Register <AiOutlineLogin />
            </Link>
          </div>
          <img src={main} alt='job hunt' className='img main-img' />
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  .container {
    min-height: calc(100vh - 6rem);
    display: grid;
    align-items: center;
    margin-top: -3rem;
  }
  nav {
    width: var(--fluid-width);
    max-width: var(--max-width);
    margin: 0 auto;
    height: 6rem;
    display: flex;
    align-items: center;
  }
  h1 {
    font-weight: 700;
  }
  .main-img {
    display: none;
  }
  @media (min-width: 992px) {
    .container {
      grid-template-columns: 1fr 1fr;
      column-gap: 6rem;
    }
    .main-img {
      display: block;
    }
  }
`;

export default Home;

import { useState,useEffect} from 'react';
import styled from 'styled-components';
import { useGlobalContext,useGlobalActionContext } from '../context/appContext';
import { Redirect } from 'react-router-dom';
import FormRow from '../components/FormRow';
import logo from '../assets/taskslogo.png';
import {Link } from 'react-router-dom';
import { AiOutlineLogin } from 'react-icons/ai';


function Register(props) {
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    isMember: true,
    errors: [],
    showAlertHere: true,
    avatar: { 
      name: 'user_icon.png',
      url:  '/user_icon.png',
      mimetype: 'image/png',
      size: 420
    }
  });
  const [selectedFile,setSelectedFile] = useState(null);
  const [errorUploading, setErrorUploading] = useState('');
  const uploadsInitMsg = 'Change avatar';
  const maxAllowedSize = 50*1024;
  

  const { 
    user, 
    register, 
    login, 
    isLoading,
    showAlert,
    uploadedFiles,
    errorMessage 
  } = useGlobalContext();

  const {
    uploadFilesTesterwlFinal,
    deleteAllTempFiles
  } = useGlobalActionContext();

  const toggleMember = () => {
    setValues({ ...values, isMember: !values.isMember, errors: [],showAlertHere:false });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeHandler = (event) => {
    if(event.target.files.length>0) {
      if(parseInt(event.target.files[0].size) > maxAllowedSize) {
        setErrorUploading('Larger then ' +maxAllowedSize/1024+' kb. Please try again!');
        event.target.value=[];
      } else {
        setErrorUploading('');
        setSelectedFile(event.target.files);
      }
    }
  };

  useEffect(() => {
    if(uploadedFiles.length>0) {
      //console.log(uploadedFiles);
      if(selectedFile){
        console.log(uploadedFiles);
        const lastUpdated = uploadedFiles.filter(file=>file.name===selectedFile[0].name);
        if(lastUpdated.length>0) {
          console.log(lastUpdated[0]);
          setValues(prevValues => ({ ...prevValues, avatar: lastUpdated[0] }));
        } else {
          console.log(uploadedFiles[uploadedFiles.length -1]);
          setValues(prevValues => ({ ...prevValues, avatar: uploadedFiles[uploadedFiles.length -1] }));
        }
      } 
    }
  }, [uploadedFiles,selectedFile]);

  useEffect(() => {
    if(selectedFile) {
      uploadFilesTesterwlFinal(selectedFile,'tmp');
    } 
  }, [selectedFile]);

  useEffect(() => {
    return () => {
      deleteAllTempFiles()
    }
  }, []);

  

  const onSubmit = async(e) => {
    e.preventDefault();
    setErrorUploading('');
    const { name, email, password, isMember, avatar} = values;

    if (isMember) {
      let newErrors = []
      let showAlertHereNew = false
      if(!email || !password) {
        if(!email) {
          newErrors['email'] = 'Email must be provided';
        }
        if(!password) {
          newErrors['password'] = 'Password must be provided';
        }
        
      } else {
        showAlertHereNew = true
        login({ email, password });
      }
      setValues({ ...values, errors: newErrors, showAlertHere:showAlertHereNew });
    } else {
      let newErrors = []
      let showAlertHereNew = false
      if(!name || !email || !password) {
        if(!name) {
          newErrors['name'] = 'Name must be provided';
        }
        if(!email) {
          newErrors['email'] = 'Email must be provided';
        }
        if(!password) {
          newErrors['password'] = 'Password must be provided';
        }
      } else {
        showAlertHereNew=true;
        register({ name, email, password,avatar},selectedFile);
        setSelectedFile(null);
      }
      setValues({ ...values, errors: newErrors, showAlertHere:showAlertHereNew });
    }
  };
  
  
  return (
    <>
      {user && <Redirect to='/dashboard' />}
      <Wrapper className='page full-page'>
        <div className='container'>
          {values.showAlertHere && showAlert && (
            <div className='alert alert-danger'>
              {errorMessage}
            </div>
          )}
          <form className='form' onSubmit={onSubmit}>
            <Link to="/">
              <img src={logo}  alt='jobio' className='logo' />
            </Link>
            
            <h4>{values.isMember ? 'Login' : 'Register'}</h4>
            {/* name field */}
            {!values.isMember && (
              <FormRow
                type='name'
                name='name'
                value={values.name}
                handleChange={handleChange}
                placeholder={typeof values.errors.name!=='undefined'?values.errors.name:''}
              />
            )}

            {/* single form row */}
            <FormRow
              type='email'
              name='email'
              value={values.email}
              handleChange={handleChange}
              placeholder={typeof values.errors.email!=='undefined'?values.errors.email:''}
            />
            {/* end of single form row */}
            {/* single form row */}
            <FormRow
              type='password'
              name='password'
              value={values.password}
              handleChange={handleChange}
              placeholder={typeof values.errors.password!=='undefined'?values.errors.password:''}
            />
            {!values.isMember && (
              <div className="form-row">
                <div className="avatar-wrapper" >
                  <label htmlFor="files" className="form-label" style={{cursor:'pointer'}} ><img src={values.avatar.url} alt={values.avatar.name} />{errorUploading !==''?errorUploading:uploadsInitMsg}</label>
                  <input id="files" name="files"  type="file" onChange={onChangeHandler} style={{visibility:'hidden', position:'absolute'}} />
                </div>
              </div>
            )}
            
            {/* end of single form row */}
            <button
              type='submit'
              className='btn btn-block'
              disabled={isLoading}
            >
              {isLoading ? 'Fetching User...' : 'Submit'} <AiOutlineLogin />
            </button>
            <p>
              For testing log with <br />username "testmode@test.com", password "123456"
            </p>
            <p className="member">
              {values.isMember ? 'Not a member yet?' : 'Already a member?'}

              <button
                type='button'
                onClick={toggleMember}
                className='member-btn'
              >
                {values.isMember ? 'Register' : 'Login'} 
              </button>
            </p>
          </form>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  display: grid;
  align-items: center;
  .logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 1.38rem;
  }
  .form {
    max-width: 400;
    border-top: 5px solid var(--primary-500);
  }

  h4 {
    text-align: center;
  }
  p {
    margin: 0;
    margin-top: 1rem;
    text-align: center;
  }
  .btn {
    margin-top: 1rem;
  }
  .member-btn {
    background: transparent;
    border: transparent;
    color: var(--primary-500);
    cursor: pointer;
  }
`;

export default Register;

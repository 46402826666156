import { useGlobalContext } from '../context/appContext';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FiEdit, FiDelete } from 'react-icons/fi';
import moment from 'moment';
import JobColumns from './JobColumns';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Jobs = () => {
  const { jobs, isLoading, deleteJob,userId } = useGlobalContext();
  // const {
  //   // downloadFileTesterwl
  // } = useGlobalActionContext();

  if (isLoading) {
    return <div className='loading'></div>;
  }

  if (typeof jobs ==='undefined' || jobs.length < 1) {
    return (
      <EmptyContainer>
        <h5 className="empty-serach">
          Currently, you have no <span>Tasks </span>
          to display
        </h5>
      </EmptyContainer>
    );
  }
  
  return (
    <>
      <JobColumns />
      <div className="scrooling">
      <Container>
        {jobs.map((item) => {
         
          const { _id: id, name, description,importance,attachmentsUrls,createdBy,appointedTo,tillDate, status, createdAt,updatedAt } = item;
          const statusColor = (status==='appointed'?'yelow status':status==='started'?'green status':status==='completed'?'blue status':'red status');
          const impColor = (importance==='not important'?'blue status':importance==='normal'?'green status':importance==='important'?'red status':'status');
          let datetill = moment(tillDate);
          let datec = moment(createdAt);
          let dateu = moment(updatedAt);
          datetill = datetill.format('MMMM Do, YYYY HH:mm a');
          datec = datec.format('MMMM Do, YYYY');
          let datech = moment(createdAt).format('HH:mm a');
          dateu = dateu.format('MMMM Do, YYYY HH:mm a');
          let nameCreatedBy = createdBy.name
          let nameAppointedTo = appointedTo.name
          // if(users.length > 0){
          //   nameCreatedBy = users.filter((us)=>us._id === createdBy)[0].name
          //   nameAppointedTo = users.filter((us)=>us._id === appointedTo)[0].name
          // }
          let exceeded = false;
          //console.log(moment().isAfter(moment(tillDate)));
          if(status !=='completed' && moment().isAfter(moment(tillDate))) {
            exceeded = true;
          }
          //console.log(createdBy.avatar);
          return (
            <article key={id} className='job'>
              <span className='name'><h5 style={{marginBottom:0}}><b>{name}</b></h5>
                <b>Assigned to</b>: <span className="user">{nameAppointedTo}{typeof appointedTo.avatar !=='undefined' && (
                  <img className="avatar-image" src={appointedTo.avatar.url} alt={appointedTo.avatar.name}  />
                )}</span> 
                <span className='task-info'>
                <br /><b>Creted at</b>: {datec} {datech}<br /> by <span className="user">{nameCreatedBy}{typeof createdBy.avatar !=='undefined' && (
                  <img className="avatar-image" src={createdBy.avatar.url} alt={createdBy.avatar.name}  />
                )} </span>
                <br />
                {status !=='completed' ? (
                  <span className='date'>last changed: {dateu}</span>
                ):(
                  <span className='date'>completed: {dateu}</span>
                )}
                </span>
              </span>
              <span className='description'>
                <b>Importance:</b> <span className={impColor}>{importance}</span>
                <br />{description.length>120?`${description.substring(0,120)} ...`:description}
                <br />{attachmentsUrls.length>0 && (
                    <b>Attachments: </b>
                  )}
                  {attachmentsUrls.map((file,index)=>(
                    <span key={index} style={{marginRight:'10px'}}>
                      <a  href={file.url} target="_blank" rel="noreferrer">
                        {file.name.split('--')[1]?file.name.split('--')[1]:file.name.split('--')[0]}
                      </a>
                    </span>
                  ))}
              </span>
              {!exceeded?(
                <span className={statusColor}> {status}</span>
              ):(
                <span className='red status'> Exceeded</span>
              )}
              
              <span className='created_at'> {datetill}</span>
              
              <div className='action-div'>
                <Link to={`/edit/${id}`} className='edit-btn' type='button'>
                  <FiEdit />
                </Link>
                {createdBy._id===userId && (
                  <Popup
                    trigger={<button className="delete-btn"> <FiDelete /> </button>}
                    modal
                    nested
                  >
                    { close => (
                      <div className="modal">
                        <button className="close" onClick={close}>
                          &times;
                        </button>
                        <div className="header"> Remove task </div>
                        <div className="content">
                          <div>Are you sure to remove this task:</div>
                          <div><b>{name}</b> ?</div>
                        </div>
                        <div className="actions">
                          <button
                            className="button btn"
                            onClick={() => {
                              deleteJob(id);
                            }}
                          >
                            OK
                          </button>
                          <button
                            className="button btn"
                            onClick={() => {
                              close();
                            }}
                            style={{float:'right'}}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                  </Popup>
                )}
              </div>
              
            </article>
          );
        })}
      </Container>
      </div>
    </>
  );
};
const EmptyContainer = styled.section`
  text-align: center;
  h5 {
    text-transform: none;
  }
  span {
    color: var(--primary-500);
  }
`;
const Container = styled.section`
  background: var(--white);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  .user {
    color:#645CFF;
  }
  .job {
    border-bottom: 1px solid var(--grey-200);
    display: grid;
    grid-template-columns: 140px 140px 70px 70px 70px;
    align-items: start;
    padding: 10px 10px;
    column-gap: 10px;
  }
  @media (min-width: 776px) {
    .job {
    grid-template-columns: 0.8fr 0.8fr 100px 150px 100px;
    align-items: start;
    padding: 1rem 1.5rem;
    column-gap: 1rem;
  }
  }
  .job:last-child {
    border-bottom: none;
  }
  span {
    font-size: var(--small-text);
  }
  .company,
  .position {
    text-transform: capitalize;
  }
  .date {
    font-weight: 400;
    color: var(--grey-500);
  }
  .status {
    color: var(--grey-500);
    border: 1px solid var(--grey-500);
    border-radius: var(--borderRadius);
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
    text-align: center;
  }
  .edit-btn {
    color: var(--green-dark);
    background: var(--green-light);
    border-color: transparent;
    border-radius: var(--borderRadius);
    cursor: pointer;
    display: inline-block;
  }
  .delete-btn {
    color: var(--red-dark);
    background: var(--red-light);
    border-color: transparent;
    border-radius: var(--borderRadius);
    cursor: pointer;
  }
  .status,
  .edit-btn,
  .delete-btn {
    padding: 0.15rem 0.5rem;
    font-size: var(--smallText);
    white-space: nowrap;
  }
  .edit-btn,
  .delete-btn {
    font-size: 1rem;
  }
  .action-div {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    gap: 0 0.5rem;
  }
`;

export default Jobs;

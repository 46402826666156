import { useState, useEffect,useRef } from 'react';
import { useParams, 
  Redirect, 
  Link 
} from 'react-router-dom';
import styled from 'styled-components';
import { useGlobalContext,useGlobalActionContext } from '../context/appContext';
import Navbar from '../components/Navbar';
import moment from 'moment';
import logo from '../assets/taskslogo.png';
import userIcon from '../assets/user_icon.png'
import { RiUserAddFill,RiFileDownloadFill } from 'react-icons/ri';
import { AiFillDelete } from 'react-icons/ai';
import axios from 'axios'
import '../axios'

function Update() {
  const { id } = useParams();
  const {
    socket,
    showAlert,
    isLoading,
    editItem,
    singleJobError: error,
    user,
    users, 
    userId,
    editJob,
    editComplete,
    uploadedFiles, 
    importanceList,
    statusList
  } = useGlobalContext();

  const {
    fetchSingleJob,
    fetchUseres,
    initUploadedFiles,
    uploadFilesTesterwlFinal,
    deleteFileTesterwlFinal,
    downloadFileTesterwl,
    deleteAllTempFiles
  } = useGlobalActionContext();

  const [values, setValues] = useState({
    name: '', 
    description: '',
    appointedTo: userId,
    tillDate: moment().format(moment.HTML5_FMT.DATETIME_LOCAL),
    importance:'normal',
    status: 'appointed',
    position: 0,
    createdBy:'',
    attachmentsUrls:[],
    public: false
  });
  const [completed, setCompleted] =  useState(false);
  const [exceeded, setExceeded] =  useState(false);

  const minDateTime = moment().add(30, 'minutes').format('YYYY-MM-DDTHH:MM');

  const [newFiles, setNewFiles] = useState([]);
  const [deletedOldFiles, setDeletedOldFiles]= useState([]);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [errorUploading, setErrorUploading] = useState([]);
  const maxAllowedSize = 7*1024*1024;
  const inputFilesRef = useRef();
  const textareaRewf = useRef();

  const getCommentsData = (res) => {
    //console.log(res);
    getComments();
  }


  useEffect(() => {
    fetchSingleJob(id);
    fetchUseres();
    socket.on("get_comments_data", getCommentsData);
    //socket.on("get_tasks_data", getTasksData);
    initUploadedFiles();
    getComments();
    setTimeout(initTextareaHeight,1000);
    return () => {
      deleteAllTempFiles();
    }
  }, [id]);

  function OnInput(e) {
    this.style.height = "7rem";
    this.style.height = (this.scrollHeight) + "px";
  }

  const initTextareaHeight = () => {
    if(textareaRewf.current) {
      textareaRewf.current.style.height = textareaRewf.current.scrollHeight+'px';
      textareaRewf.current.addEventListener("input", OnInput, false);
    }
  }
  
  useEffect(() => {
    if (editItem) {
      const { name, description, appointedTo,createdBy ,tillDate, importance, status, position, createdAt,attachmentsUrls } = editItem;
      setValues(prevValues => ( {...prevValues,name, description, appointedTo,tillDate, importance, status, position,createdBy, createdAt,attachmentsUrls,public:editItem.public}) );
      if(status === 'completed') {
        setCompleted(true);
      }
      if(status !== 'completed' && moment().isAfter(moment(tillDate))) {
        setExceeded(true);
      }
    }
  }, [editItem]);

  const removeFromAttachedFile = (e,file) => {
    e.preventDefault();
    //ce fisier nou/vechi ???
    if(file.url.includes('/tmp')) {
      setNewFiles(newFiles.filter(f=>f.name!==file.name))
      deleteFileTesterwlFinal(file,'tmp');
    } else {
      let newAttachmentsUrls = values.attachmentsUrls.filter(item=>item.name!==file.name)
      setValues({ ...values,attachmentsUrls:newAttachmentsUrls });
      setDeletedOldFiles([...deletedOldFiles, file])
    }
    //inputFilesRef.current.value =[];
  }

  const downloadFile = (e, file) => {
    //ce fisier nou/vechi ???
    if(file.url.includes('/tmp')) {
      downloadFileTesterwl(e, file)
    } else {
      downloadFileTesterwl(e, file,id)
    }
  }

  const handleChange = (e) => {
    let val = e.target.value;
    if(e.target.name === 'status') {
      if(e.target.value === 'completed') {
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    } 
    if(e.target.name === 'tillDate') {
      if(!completed && moment().isBefore(moment(e.target.value))) {
        setExceeded(false);
      } else if (!completed && moment().isAfter(moment(e.target.value))) {
        setExceeded(true);
      }
    }
    if(e.target.name === 'public') {
      val = e.target.checked
    }
    setValues({ ...values, [e.target.name]: val });
    // alert(e.target.name+','+e.target.value)
  };

  const onChangeHandler = (event) => {
    event.preventDefault();
    if(event.target.files.length>0) {
      let errors = [];
      for(let i=0; i<event.target.files.length;i++) {
        if (parseInt(event.target.files[i].size) > maxAllowedSize) {
          errors= [...errors, 'File '+event.target.files[i].name + ' larger then' +maxAllowedSize/1024+' kb']
        }
      }
      if (errors.length>0) {
        setErrorUploading([]);
        setErrorUploading([...errors, 'Please try again!']);
        event.target.value=[];
      } else {
        let newSelected = newFiles.concat(Array.from(event.target.files));
        let newSelectedUniques = [...new Map(newSelected.map(item =>[item['name'], item])).values()];
        setNewFiles(newSelectedUniques)
        uploadFilesTesterwlFinal(newSelectedUniques,'tmp')
      }
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorUploading([]);
    const { name, description, appointedTo ,tillDate, importance, status, position, attachmentsUrls } = values;
    if (name && description && appointedTo && tillDate) {
      editJob(id, { name, description, appointedTo ,tillDate:moment(tillDate).format(moment.HTML5_FMT.DATETIME_LOCAL), importance, status, position, attachmentsUrls, public:values.public }, newFiles, deletedOldFiles);
      setNewFiles([]);
      setTimeout(function(){ 
        if(inputFilesRef.current) {
          inputFilesRef.current.value = []
        } 
      },1000)
    }
  };

  const getComments =  () => {
    let query = `taskId=${id}`;
    axios.get(`/comments?${query}`)
    .then(res=> {
      if(res.data.comments){
        setComments(res.data.comments)
      }
    }).catch((err) => {
      //console.log(err)
    }) 
  }

  const getCommentsUserAvatar = (comment) => {
    let avatar = { url: userIcon, name:comment.userName};
    if(users.length>0) {
      const us = users.filter(u=>u.name===comment.userName);
      if(us.length>0) {
        if(typeof us[0].avatar !== undefined) {
           avatar = us[0].avatar
        }
      }
    } 
    return avatar;
  }

  const addComment =  (e) => {
    e.preventDefault();
    let input = {
      text:comment,
      task: id,
      userName: user
    }
    axios.post(`/comments`, {...input})
    .then(res => {
      if(res.data.comment) {
        setComments([res.data.comment, ...comments])
        setComment('');
      }
    })
  }

  const handleCommmentChange = (e) => {
    setComment( e.target.value );
  }

  const removeComment = (commentId) => {
    axios.delete(`/comments/comment/${commentId}`)
    .then(res => {
      const newComments=comments.filter(item=>item._id!==commentId);
      setComments(newComments)
    })
  }


  if (isLoading && !editItem) {
    return <div className='loading'></div>;
  }
 
  if (!editItem || error) {
    return (
      <>
        {!localStorage.getItem('user') && <Redirect to='/' />}
        {(user ) && (
          <ErrorContainer className='page'>
            <h5>There was an error, please double check your task ID</h5>

            <Link to='/dashboard' className='btn'>
              dasboard
            </Link>
          </ErrorContainer>
        )}
      </>
    );
  }

  
  let tillDate = moment(values.tillDate).format(moment.HTML5_FMT.DATETIME_LOCAL)
  
  return (
    <div>
      {!localStorage.getItem('user') && <Redirect to='/' />}
      {(user && values.name!=='') && (
        <>
          <Navbar />
          <Container className='page'>
            {showAlert && (
              <div className='alert alert-danger'>
                there was an error, please try again
              </div>
            )}
            <form className='form' onSubmit={handleSubmit}>
              <p>{editComplete && 'Success! Update Complete'}</p>
              
              {/* company */}
              <div className='form-container'>
                <div className='form-row'>
                  {values.createdBy._id===userId?(
                    <input type="text" name="name" value={values.name} placeholder='Name' onChange={handleChange} className="form-input margin-bottom" />
                  ):(
                    <h4><b>{values.name}</b><span className="little">( Created by <b>{values.createdBy.name}</b> at {moment(values.createdAt).format('MMMM Do, YYYY HH:mm a')})</span></h4>
                  )}
                  
                  {values.createdBy._id!==userId && values.appointedTo._id!==userId?(
                    <div>
                      <label className="margin-bottom" htmlFor="appointedTo">Description: {values.description}</label>
                    </div>
                  ):(
                    <textarea 
                      className="form-textarea"
                      ref={textareaRewf}
                      name="description" 
                      value={values.description} 
                      placeholder="Description"
                      onChange={handleChange}
                    >
                    </textarea>
                  )}
                  
                  {(values.createdBy._id===userId || values.appointedTo._id===userId) ? (
                    <div>
                      <div>
                        <label htmlFor="files"  className="btn-dashed">{errorUploading.length>0?(
                          errorUploading.map((item, index) => (<div key={index}>{item}</div>))
                        ):'Select Attachments'}</label>
                        <input id="files" ref={inputFilesRef} name="files" style={{visibility:'hidden'}}  type="file" multiple onChange={onChangeHandler}  />
                      </div>
                      <div className="filesWrapper">
                        {values.attachmentsUrls.concat(uploadedFiles).map((file, index) => (
                          <span key={index} style={{marginRight:'10px'}}>
                            
                            <a  href={file.url} target="_blank" rel="noreferrer">
                              {file.mimetype && file.mimetype.includes('image/')?(
                                <img src={file.url} alt={file.name} width="80px" />
                              ):file.name.split('--')[1]?file.name.split('--')[1]:file.name.split('--')[0]} 
                            </a>
                            <div className="images-actions">
                              <button onClick={(e)=>downloadFile(e,file)} style={{marginLeft:'5px', marginRight:'5px'}}>
                                <RiFileDownloadFill />
                              </button>
                              <button onClick={(e)=>removeFromAttachedFile(e,file)} style={{marginLeft:'5px', marginRight:'5px'}}>
                                <AiFillDelete />
                              </button>
                            </div>
                          </span>
                        ))}
                      </div>
                    </div>
                  ):(
                    <div className="filesWrapper">
                      {values.attachmentsUrls.concat(uploadedFiles).map((file, index) => (
                        <span key={index} style={{marginRight:'10px'}}>
                          
                          <a  href={file.url} target="_blank" rel="noreferrer">
                            {file.mimetype && file.mimetype.includes('image/')?(
                              <img src={file.url} alt={file.name} width="80px" />
                            ):file.name.split('--')[1]?file.name.split('--')[1]:file.name.split('--')[0]} 
                          </a>
                          <div className="images-actions">
                            <button onClick={(e)=>downloadFile(e,file)} style={{marginLeft:'5px', marginRight:'5px'}}>
                              <RiFileDownloadFill />
                            </button>
                          </div>
                        </span>
                      ))}
                    </div>
                  )}
                  
                  
                </div>
                <div className='form-row'>
                  {values.createdBy._id===userId?!completed?
                      exceeded?(
                        <div>
                          <input className="form-input" type="datetime-local" name="tillDate" value={tillDate} min={minDateTime} onChange={handleChange} />
                          <div>Task Exceeded. Date must be greater then {moment(minDateTime).format('YYYY-MM-DD hh:mm')} or Completed</div>
                        </div>
                      ):(
                        <div>
                          <input className="form-input" type="datetime-local" name="tillDate" value={tillDate} min={minDateTime} onChange={handleChange} />
                          <label className="form-label" htmlFor="tillDate">Limit date to accomplish: </label>
                        </div>
                      )
                  :(
                    <label className="margin-bottom" htmlFor="tillDate">Task was up to <b>{moment(tillDate).format('YYYY-MM-DD hh:mm')}</b></label>
                  ):!completed?(
                    <div>
                      {exceeded? (
                        <h5>Task Exceeded up to date: {moment(tillDate).format('YYYY-MM-DD hh:mm')}</h5>
                      ):(
                        <label className="margin-bottom" htmlFor="tillDate">Task up to <b>{moment(tillDate).format('YYYY-MM-DD hh:mm')}</b></label>
                      )}
                      
                    </div>
                  ):(
                    <label className="margin-bottom" htmlFor="tillDate">Task was up to <b>{moment(tillDate).format('YYYY-MM-DD hh:mm')}</b></label>
                  )}
                  
                  {values.createdBy._id===userId ?(
                    <select className="form-select" name="importance" value={values.importance} onChange={handleChange}>
                      {importanceList.map((item)=>(
                        <option key={item.id} value={item.id} >{item.name}</option>
                      ))}
                    </select>
                  ):(
                    <h4>{values.importance}</h4>
                  )}
                  
                  {values.createdBy._id===userId?(
                    <div>
                      <label className="form-label" htmlFor="appointedTo">Asigned to: </label>
                      <select className="form-select" name="appointedTo" value={values.appointedTo._id?values.appointedTo._id:userId} onChange={handleChange}>
                        {users.map((user,index)=>(
                          <option key={index} value={user._id}>{user.name}</option>
                        ))}
                      </select>
                    </div>
                  ):(
                    <div>
                      <label className="margin-bottom" htmlFor="appointedTo">Asigned to: {values.appointedTo.name}</label>
                    </div>
                  )}
                  
                  {(values.createdBy._id===userId || values.appointedTo._id===userId) ? (
                    <div>
                      <label className="form-label" htmlFor="status">Status: </label>
                      <select className="form-select" name="status" value={values.status} onChange={handleChange}>
                        {statusList.map((item)=>(
                          <option key={item.id} value={item.id} >{item.name}</option>
                        ))}
                      </select>
                    </div>
                  ):(
                    <div>
                      <label className="margin-bottom" htmlFor="appointedTo">Status: {values.status}</label>
                    </div>
                  )}
                  
                </div>

                {(values.createdBy._id===userId || values.appointedTo._id===userId) ? (
                  <div className='form-row'>
                    <button
                      type='submit'
                      className='btn btn-block submit-btn btn-right-bottom'
                      disabled={isLoading}
                    >
                      {isLoading ? 'Updating...' : 'Update'}
                    </button>

                    <Link to='/dashboard' className='btn btn-block back-home btn-right-bottom'>
                      back home
                    </Link>
                    {values.createdBy._id===userId ? (
                      <div className="check-wrapper">
                        <input type="checkbox" id="public" name="public" checked={values.public} onChange={handleChange} />
                        <label className="form-label" htmlFor="public"> Public</label>
                      </div>
                    ):(
                      <label className="margin-bottom" htmlFor="appointedTo">{values.public?'Public':'Private'}</label>
                    )}
                    
                    <input type="number" name="position" min="0" value={values.position} onChange={handleChange} className="form-input" />
                    <label className="form-label" htmlFor="position">Position: </label>
                    <img className="logo left" src={logo} alt='jobs app' />
                  </div>
                ):(
                  <div className='form-row'>
                    <Link to='/dashboard' className='btn btn-block back-home btn-right-bottom'>
                      back home
                    </Link>
                    <label className="margin-bottom" htmlFor="appointedTo">{values.public?'Public':'Private'}</label>
                  </div>
                )}
                
                
              </div>
            </form>

            <div className="comments-wrapper">
          
              <form  onSubmit={addComment}>
                <div>
                  <textarea 
                    className="form-textarea"
                    name="comment" 
                    value={comment} 
                    placeholder="add Comment"
                    onChange={handleCommmentChange}
                    style={{maxWidth:'500px', height:'50px'}}
                  >
                  </textarea>
                  
                  <button
                    type='submit'
                    className='btn btn-block submit-btn auto-width'
                    style={{float:'right'}}
                  >
                    Add Comment
                  </button>
                </div>
              </form>
              <b>Comments:</b>
              
              {comments.length>0?(
                <div>
                  {comments.map((item)=>{
                    const avatar = getCommentsUserAvatar(item);
                    return (<div key={item._id} className="comment">
                      <b className="username-comment">{avatar ? (
                        <img className="avatar-image" src={avatar.url} alt={avatar.name} />
                      ):(<RiUserAddFill />)} {item.userName}: </b> <span> {item.text}</span>
                      <br /><span className="little">at {moment(item.createdAt).format('MMMM Do, YYYY HH:mm a')}</span>
                      {user===item.userName && (
                        <button className="delete-comment" onClick={()=>removeComment(item._id)} style={{marginLeft:'5px', marginRight:'15px'}}>
                          <AiFillDelete />
                        </button>
                      )}
                    </div>
                  )}
                  )}
                </div>
              ):(
                <EmptyContainer>
                  <h5 >
                    Currently, you have no <span>Comments </span>
                    to display
                  </h5>
                </EmptyContainer>
              )}
            </div>
          </Container>
        </>
      )}
    </div>
  );
}
const EmptyContainer = styled.section`
  text-align: center;
  h5 {
    text-transform: none;
  }
  span {
    color: var(--primary-500);
  }
`;
const ErrorContainer = styled.section`
  text-align: center;
  padding-top: 6rem; ;
`;

const Container = styled.section`
  footer {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .form {
    max-width: var(--max-width);
  }
  .form h4 {
    text-align: center;
  }
  .form > p {
    text-align: center;
    color: var(--green-dark);
    letter-spacing: var(--letterSpacing);
    margin-top: 0;
  }
  .status {
    background: var(--grey-100);
    border-radius: var(--borderRadius);
    border-color: transparent;
    padding: 0.25rem;
  }
  .back-home {
    text-align: center;
    display: inline-block;
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.15;
    background: var(--grey-500);
  }
  .back-home:hover {
    background: var(--black);
  }
  @media (min-width: 768px) {
    .form h4 {
      text-align: left;
    }
    .form-container {
      display: grid;
      grid-template-columns: 1fr 1fr 160px;
      column-gap: 1rem;
      align-items: start;
    }
    .btn {
      padding: 0.75rem;
    }
    .submit-btn,
    .back-home {
      margin: 0;
    }

    .form > p {
      text-align: left;
    }
  }
`;
export default Update;
